.link-item {
  text-decoration: none;
  margin-bottom: 48px;
  width: 350px;
  flex-grow: 0;
  flex-shrink: 1;
  margin-right: 20px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .link-item {
    width: 300px;
  }
}

.product-item {
  display: flex;
  flex-direction: column;
}

.thumbnail {
  width: 100%;
  max-height: 350px;
  border-radius: 6px;
}

.title {
  color: #171f46;
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 8px;
}

.brand {
  color: #594d6d;
  font-family: 'Roboto';
  font-size: 18px;
  margin-bottom: 6px;
}

.product-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2px;
}

.price {
  color: #171f46;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.rating-container {
  display: flex;
  align-items: center;
  background-color: #3b82f6;
  border-radius: 6px;
  padding: 6px 16px;
}

.rating {
  color: #ffffff;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  margin-right: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.star {
  height: 20px;
  width: 20px;
  margin-bottom: 3px;
}
