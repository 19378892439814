.primedeals-list-heading {
  color: #475569;
  font-family: 'Roboto';
  font-size: 32px;
  font-weight: 500;
  margin-top: 48px;
}

.primedeals-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}

.register-prime-image {
  margin-top: 30px;
}

.primedeals-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}
