.all-products-section {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .all-products-section {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 768px) {
  .all-products-container {
    margin-left: 30px;
    width: 70%;
  }
}

.products-heading {
  color: #475569;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .products-heading {
    font-size: 32px;
  }
}

.products-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media screen and (min-width: 768px) {
  .products-loader-container {
    width: 70%;
  }
}

.products-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}

.no-products-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 64px;
  margin-top: 48px;
}

@media screen and (min-width: 768px) {
  .no-products-view {
    margin-left: 30px;
    width: 70%;
    padding-bottom: 0;
    margin-top: 0px;
  }
}

.no-products-img {
  width: 250px;
  height: 180px;
}

@media screen and (min-width: 768px) {
  .no-products-img {
    width: 459px;
    height: 315px;
  }
}

.no-products-heading {
  color: #171f46;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3px;
}

@media screen and (min-width: 768px) {
  .no-products-heading {
    margin-top: 32px;
    font-size: 24px;
  }
}

.no-products-description {
  text-align: center;
  color: #64748b;
  font-family: 'Roboto';
  font-size: 14px;
  width: 90%;
  max-width: 288px;
  line-height: 1.3;
}

@media screen and (min-width: 768px) {
  .no-products-description {
    margin-top: 12px;
    font-size: 18px;
    width: 60%;
    max-width: 466px;
  }
}

.products-error-view-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  padding-bottom: 64px;
}

@media screen and (min-width: 768px) {
  .products-error-view-container {
    margin-top: 0px;
    margin-left: 30px;
    width: 70%;
    padding-bottom: 0;
  }
}

.products-failure-img {
  width: 250px;
  height: 200px;
}

@media screen and (min-width: 768px) {
  .products-failure-img {
    width: 400px;
    height: 350px;
  }
}

.product-failure-heading-text {
  color: #171f46;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
}

@media screen and (min-width: 768px) {
  .product-failure-heading-text {
    font-size: 24px;
  }
}

.products-failure-description {
  text-align: center;
  color: #64748b;
  font-family: 'Roboto';
  font-size: 14px;
  width: 90%;
  max-width: 288px;
  line-height: 1.3;
}

@media screen and (min-width: 768px) {
  .products-failure-description {
    font-size: 18px;
    width: 60%;
    max-width: 466px;
  }
}
