.cart-item {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 4px 16px 0px #7e858e29;
}

@media screen and (min-width: 768px) {
  .cart-item {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 768px) {
  .cart-item {
    padding-left: 36px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 48px;
  }
}

.cart-product-image {
  width: 96px;
  height: 96px;
  border-radius: 4px;
}

.cart-item-details-container {
  margin-left: 16px;
}

@media screen and (min-width: 768px) {
  .cart-item-details-container {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }
}

@media screen and (min-width: 768px) {
  .cart-product-title-brand-container {
    width: 250px;
  }
}

.cart-product-title {
  color: #171f46;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .cart-product-title {
    font-size: 16px;
  }
}

.cart-product-brand {
  color: #64748b;
  font-family: 'Roboto';
  font-size: 10px;
}

@media screen and (min-width: 768px) {
  .cart-product-brand {
    font-size: 12px;
  }
}

.cart-quantity-container {
  display: flex;
  align-items: center;
}

.quantity-controller-button {
  padding: 0;
}

.cart-quantity {
  color: #52606d;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  margin: 8px;
  line-height: 1.3;
}

@media screen and (min-width: 768px) {
  .cart-quantity {
    font-size: 18px;
    margin-left: 16px;
    margin-right: 16px;
  }
}

.total-price-delete-container {
  display: flex;
  align-items: center;
}

.cart-total-price {
  color: #0b69ff;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  min-width: 100px;
}

@media screen and (min-width: 768px) {
  .cart-total-price {
    font-size: 18px;
  }
}

.remove-button {
  background-color: transparent;
  color: #334155;
  font-family: 'Roboto';
  font-size: 10px;
  line-height: 16px;
  border: none;
  outline: none;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .remove-button {
    display: none;
  }
}

.delete-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 32px;
}

@media screen and (max-width: 767px) {
  .delete-button {
    display: none;
  }
}
