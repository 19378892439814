.similar-product-item {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

@media screen and (min-width: 768px) {
  .similar-product-item {
    width: 200px;
    margin-right: 64px;
  }
}

.similar-product-image {
  width: 200px;
  border-radius: 8px;
}

.similar-product-title {
  color: #171f46;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
}

.similar-products-brand {
  color: #594d6d;
  font-family: 'Roboto';
  font-size: 16px;
  margin-top: 6px;
}

.similar-product-price-rating-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}

.similar-product-price {
  color: #171f46;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 700;
}

.similar-product-rating-container {
  display: flex;
  align-items: center;
  background-color: #3b82f6;
  border-radius: 6px;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
}

.similar-product-rating {
  color: #ffffff;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  margin-right: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.similar-product-star {
  height: 14px;
  width: 14px;
}
