.filters-group-container {
  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  .filters-group-container {
    width: 25%;
    max-width: 280px;
    min-width: 240px;
    margin-top: 48px;
    flex-shrink: 0;
  }
}

.search-input-container {
  display: flex;
  align-items: center;
  background-color: #f1f5f9;
  border-radius: 8px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
}

.search-input {
  background-color: #f1f5f9;
  color: #0f172a;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  flex-grow: 1;
}

.search-icon {
  color: #475569;
  width: 20px;
  height: 20px;
}

.category-heading {
  color: #12022f;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 700;
  margin-top: 24px;
}

@media screen and (min-width: 768px) {
  .category-heading {
    margin-top: 32px;
  }
}

.categories-list {
  padding-left: 0;
}

.category-item {
  list-style-type: none;
  margin-top: 16px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .category-item {
    margin-top: 24px;
  }
}

.category-name {
  color: #64748b;
  font-family: 'Roboto';
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  .category-name {
    font-size: 18px;
  }
}

.active-category-name {
  color: #0967d2;
}

.rating-heading {
  color: #12022f;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 18px;
}

.ratings-list {
  padding-left: 0px;
}

.rating-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
}

.rating-image {
  max-width: 152px;
  height: 20px;
}

@media screen and (min-width: 768px) {
  .rating-image {
    height: 24px;
  }
}

.and-up {
  color: #64748b;
  font-family: Roboto;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 0;
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  .and-up {
    font-size: 18px;
    margin-left: 14px;
  }
}

.active-rating {
  color: #0967d2;
}

.clear-filters-btn {
  background-color: #ffffff;
  color: #0967d2;
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 700;
  border-radius: 4px;
  border: 1px solid #0967d2;
  padding-top: 8px;
  padding-left: 12px;
  padding-bottom: 8px;
  padding-right: 12px;
  margin-top: 16px;
  outline: none;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .clear-filters-btn {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 32px;
  }
}
